.banner-user-banner{
  height: 100%!important;
  width: 100%!important;
}
.banner-elemento-banner{
  width: 100%!important;
  height: 100%!important;
  background-repeat: no-repeat;
  background-attachment: center;
  background-position: center center;
  background-size: contain;
}